import { useAuthStore } from '~/store/auth'
import { useRootStore } from '~/store/root'
import type { Models } from '~/types/models'

import { textShortener } from '~/util/textFunction'
import type { Api } from '~~/global'

export const usePostActions = () => {
    const { $lang, $bp } = useNuxtApp()
    const RootStore = useRootStore()
    const AuthStore = useAuthStore()

    const saved = ref(false)
    const creatingNewComment = ref(false)
    const config = useRuntimeConfig()

    const { buildHeaders, baseURL, endpoints } = useApiConfig()

    const savePost = async (post: Models.Discount) => {
        if (!AuthStore.IsAuth) return AuthStore.setAuthModal({ type: 'sign-in' })
        if (!AuthStore.UserData?.verified_email) {
            AuthStore.setAuthModal({
                type: 'email-verification',
                info: {
                    isReminder: true,
                    message: `${$lang.plugins.axiosInterceptors.for} ${$lang.plugins.axiosInterceptors.do_save_discounts} ${$lang.plugins.axiosInterceptors.need_verify_account}`,
                },
            })
            return
        }
        try {
            const saveResult = await $fetch<
                Api.Responses.Actions.SuccessSave | Api.Responses.Actions.FailedSave
            >(endpoints.create.save.path, {
                method: 'POST',
                onResponse() {
                    AuthStore.updateActivitiesCount('notifications')
                },
                headers: buildHeaders(AuthStore.SessionToken),
                body: {
                    saveable_id: post.id,
                    saveable_type: post.type,
                    type: saved.value ? 0 : 1,
                },
                baseURL,
            })

            if (saveResult?.feedback === 'data_success') {
                saved.value = !saved.value
                RootStore.setSiteNotification({
                    duration: 5000,
                    dismissButtonText: $lang.components.cardMainGlobalFeatured.okay,
                    text: `${
                        post.type === 'offer'
                            ? $lang.components.cardMainGlobalFeatured.offer
                            : $lang.components.cardMainGlobalFeatured.coupon
                    } <span style="font-weight:600">${textShortener(
                        post.title,
                        $bp.mb ? 30 : $bp.xs || $bp.sm ? 60 : 90,
                    )}</span> ${
                        saved.value
                            ? post.type === 'offer'
                                ? $lang.components.cardMainGlobalFeatured.saved_a
                                : $lang.components.cardMainGlobalFeatured.saved
                            : post.type === 'offer'
                              ? $lang.components.cardMainGlobalFeatured.deleted_a
                              : $lang.components.cardMainGlobalFeatured.deleted
                    }`,
                    type: 'success',
                })
            } else if (saveResult?.feedback === 'error_fields_validation') {
                RootStore.setSiteNotification({
                    duration: 5000,
                    dismissButtonText: $lang.components.cardMainGlobalFeatured.okay,
                    text:
                        saveResult.errors.type?.[0] ||
                        saveResult.errors.saveable_type?.[0] ||
                        saveResult.errors.id?.[0] ||
                        saveResult.errors.saveable_id?.[0] ||
                        `${$lang.components.cardMainGlobalFeatured.error_on} ${
                            saved.value
                                ? $lang.components.cardMainGlobalFeatured.delete
                                : $lang.components.cardMainGlobalFeatured.save
                        }, ${$lang.components.cardMainGlobalFeatured.something_happens} ${
                            post.type === 'offer'
                                ? $lang.components.cardMainGlobalFeatured.this_offer
                                : $lang.components.cardMainGlobalFeatured.this_coupon
                        }.`,
                    type: 'error',
                })
            } else if (
                AuthStore.IsAuth &&
                AuthStore.UserData?.accepted_conditions_at &&
                AuthStore.UserData?.verified_email
            ) {
                RootStore.setSiteNotification({
                    duration: 5000,
                    dismissButtonText: $lang.components.cardMainGlobalFeatured.okay,
                    text: $lang.components.cardMainGlobalFeatured.error_on_save,
                    type: 'error',
                })
            }
        } catch (e) {
            console.log('Error on save: ', e)
        }
    }
    const reportPost = (params: {
        reportable_type: 'offer' | 'coupon' | 'comment' | 'forum'
        reportable_id: number
        reason:
            | 'Spam/Ofensivo'
            | 'Duplicado'
            | 'Enlaces referidos'
            | 'Oferta expirada o no funciona el enlace'
            | 'Otro'
            | 'Acoso o incitación al odio'
    }) => {
        if (config.public.variant === 'megadescuentos') {
            if (!AuthStore.IsAuth) return AuthStore.setAuthModal({ type: 'sign-in' })
            if (!AuthStore.UserData?.verified_email) {
                AuthStore.setAuthModal({
                    type: 'email-verification',
                    info: {
                        isReminder: true,
                        message: `${$lang.plugins.axiosInterceptors.for} ${$lang.plugins.axiosInterceptors.do_report} ${$lang.plugins.axiosInterceptors.need_verify_account}`,
                    },
                })
                return
            }
        }
        return $fetch<Api.Responses.Actions.SuccessReport | Api.Responses.Actions.FailedReport>(
            endpoints.create.report.path,
            {
                method: 'POST',
                onResponse() {
                    if (config.public.variant === 'megadescuentos') {
                        AuthStore.updateActivitiesCount('notifications')
                    }
                },
                body: params,
                headers: buildHeaders(AuthStore.SessionToken),
                baseURL,
            },
        )
    }
    const expirePost = (params: { expiredable_type: 'coupon' | 'offer'; expiredable_id: number }) => {
        if (!AuthStore.IsAuth) return AuthStore.setAuthModal({ type: 'sign-in' })
        if (!AuthStore.UserData?.verified_email) {
            AuthStore.setAuthModal({
                type: 'email-verification',
                info: {
                    isReminder: true,
                    message: `${$lang.plugins.axiosInterceptors.for} ${$lang.plugins.axiosInterceptors.do_mark_expired} ${$lang.plugins.axiosInterceptors.need_verify_account}`,
                },
            })
            return
        }
        return $fetch<Api.Responses.Actions.SuccessExpired | Api.Responses.Actions.FailedExpired>(
            endpoints.create.report.path,
            {
                method: 'POST',
                onResponse() {
                    AuthStore.updateActivitiesCount('notifications')
                },
                body: params,
                headers: buildHeaders(AuthStore.SessionToken),
                baseURL,
            },
        )
    }

    const createNewComment = async (params: {
        commentable_id: number
        commentable_type: 'offer' | 'coupon' | 'comment' | 'forum'
        content: string
    }) => {
        creatingNewComment.value = true
        try {
            const commentResult = await $fetch<
                Api.Responses.Actions.SuccessComment | Api.Responses.Actions.FailedComment
            >(endpoints.create.comments.path, {
                method: 'POST',
                onResponse() {
                    AuthStore.updateActivitiesCount('notifications')
                },
                headers: buildHeaders(AuthStore.SessionToken),
                baseURL,
                body: params,
            })

            if (
                commentResult.feedback === 'resource_created' ||
                commentResult.feedback === 'resource_needs_approval'
            ) {
                if (commentResult.feedback === 'resource_created') {
                    RootStore.setSiteNotification({
                        duration: 5000,
                        dismissButtonText: $lang.views.discountDetails.close,
                        text: $lang.views.discountDetails.comment_published,
                        type: 'success',
                    })
                }
                if (commentResult.feedback === 'resource_needs_approval') {
                    RootStore.setSiteNotification({
                        duration: 5000,
                        dismissButtonText: $lang.views.discountDetails.hide,
                        text: $lang.views.discountDetails.comment_pending,
                        type: 'help',
                    })
                }
                creatingNewComment.value = false
                return commentResult
            }
        } catch (e) {
            RootStore.setSiteNotification({
                duration: 5000,
                dismissButtonText: $lang.views.discountDetails.hide,
                text: $lang.views.discountDetails.comment_cant_create,
                type: 'error',
            })
            creatingNewComment.value = false
            return false
        }
        creatingNewComment.value = false
    }

    const makeVote = async (vote: {
        voteable_type: 'coupon' | 'offer'
        voteable_id: number
        type: 0 | 1
    }) => {
        if (!AuthStore.IsAuth) return AuthStore.setAuthModal({ type: 'sign-in' })
        if (!AuthStore.UserData?.verified_email) {
            AuthStore.setAuthModal({
                type: 'email-verification',
                info: {
                    isReminder: true,
                    message: `${$lang.plugins.axiosInterceptors.for} ${$lang.plugins.axiosInterceptors.do_votes} ${$lang.plugins.axiosInterceptors.need_verify_account}`,
                },
            })
            return
        }

        return $fetch<Api.Responses.Actions.SuccessVote | Api.Responses.Actions.FailedVote>(
            endpoints.create.votes.path,
            {
                baseURL,
                headers: buildHeaders(AuthStore.SessionToken),
                method: 'post',
                body: vote,
            },
        )
    }

    return {
        makeVote,
        savePost,
        expirePost,
        reportPost,
        createNewComment,
        creatingNewComment,
        saved,
    }
}
