<template>
    <div class="heat-counter-layout" :class="scale">
        <div class="heat-counter-layout_vote">
            <div class="heat-counter-layout_vote_wrapper">
                <button
                    v-if="typeof alreadyVoted !== 'number' || alreadyVoted === 0"
                    :disabled="!!voting || !!expire || typeof alreadyVoted === 'number'"
                    :class="scale"
                    @click="makeVote(0)"
                >
                    <img
                        class="heat-image"
                        :src="$assets[leftColor][voting === 'left' ? 'loading' : 'arrowLeft']"
                        alt="enfriar"
                        title="Votar para abajo"
                        width="20px"
                        height="20px"
                        :class="{ spin: voting === 'left' }"
                    />
                </button>
                <span :class="[textColor, scale]">{{ newCount || count }}º</span>
                <button
                    v-if="typeof alreadyVoted !== 'number' || alreadyVoted === 1"
                    :disabled="!!voting || !!expire || typeof alreadyVoted === 'number'"
                    :class="scale"
                    @click="makeVote(1)"
                >
                    <img
                        class="heat-image"
                        :src="$assets[rightColor][voting === 'right' ? 'loading' : 'arrowRight']"
                        alt="calentar"
                        title="Votar para arriba"
                        width="20px"
                        height="20px"
                        :class="{ spin: voting === 'right' }"
                    />
                </button>
            </div>
        </div>
        <span class="heat-counter-layout_icon" :class="scale"
            ><img v-if="fire" :src="$assets[fireColor].fire" alt="temperatura" width="24px" height="24px"
        /></span>
        <p v-if="expire && showExpire">{{ $lang.components.microHeatCounterVote.expired }}</p>
    </div>
</template>

<script lang="ts">
import { type PropType, defineComponent } from 'vue'
import type { Api } from '~/global'
import { useAuthStore } from '~/store/auth'

export default defineComponent({
    name: 'MicroHeatCounterVote',
    props: {
        fire: {
            type: Boolean,
            default: true,
        },
        scale: String as PropType<'small' | undefined>,
        voted: {
            type: [Boolean, Number],
            default: false,
        },
        count: {
            type: Number,
            default: 0,
            required: true,
        },
        voteableId: {
            type: Number,
        },
        voteableType: {
            type: String,
        },
        expire: {
            type: Boolean,
            default: false,
        },
        showExpire: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        const { SessionToken } = useAuthStore()
        const { makeVote } = usePostActions()
        return {
            makeVoteAction: makeVote,
            SessionToken,
            newCount: null as null | number,
            alreadyVoted: this.voted as boolean | number,
            voting: false as boolean | string,
        }
    },
    computed: {
        leftColor(): 'gray' | 'blue' {
            return typeof this.alreadyVoted === 'number' ? 'gray' : 'blue'
        },
        rightColor(): 'red' | 'gray' {
            return typeof this.alreadyVoted === 'number' ? 'gray' : 'red'
        },
        fireColor(): 'red' | 'orange' | 'gray' | 'blue' {
            return (this.newCount || this.count) > 100
                ? 'red'
                : (this.newCount || this.count) > 50
                  ? 'orange'
                  : (this.newCount || this.count) > 0
                    ? 'gray'
                    : 'blue'
        },
        textColor(): 'text-red-500' | 'text-orange-500' | 'text-gray-800' | 'text-sky-600' {
            return (this.newCount || this.count) > 100
                ? 'text-red-500'
                : (this.newCount || this.count) > 50
                  ? 'text-orange-500'
                  : (this.newCount || this.count) > 0
                    ? 'text-gray-800'
                    : 'text-sky-600'
        },
    },
    methods: {
        async makeVote(userVoted: 0 | 1) {
            this.voting = userVoted ? 'right' : 'left'

            try {
                if (this.voteableId && this.voteableType) {
                    const voteResult = await this.makeVoteAction({
                        voteable_type: this.voteableType as 'coupon' | 'offer',
                        voteable_id: this.voteableId,
                        type: userVoted,
                    })

                    if (voteResult && voteResult.feedback === 'data_success') {
                        this.alreadyVoted = userVoted

                        this.newCount = this.count + (userVoted ? 1 : -1)
                    }
                }
            } catch (e) {}
            this.voting = false
        },
    },
})
</script>

<style lang="postcss" scoped>
.heat-counter-layout {
    @apply flex items-center space-x-1;
    &_vote {
        @apply flex items-center rounded-2xl bg-white px-2 py-1 text-white shadow-xl;
        box-shadow:
            0 0 25px rgb(0 0 0 / 0.1),
            0 8px 10px -6px rgb(0 0 0 / 0.1);
        &_wrapper {
            @apply flex space-x-1;
            button {
                @apply h-5 w-5 self-center;
                .heat-image {
                    @apply h-full w-full object-contain;
                }
                &.small {
                    @apply h-3 w-3;
                }
            }
            span {
                @apply text-base font-semibold;
                &.small {
                    @apply text-xs;
                }
            }
        }
    }
    &_icon {
        @apply h-6 w-6 self-center;
        img {
            @apply h-full w-full object-contain;
        }
        &.small {
            @apply h-4 w-4;
        }
    }
    p {
        @apply text-lg font-medium text-[#94949B];
    }
    .register-classes {
        @apply text-sky-600;
    }
}
</style>
